import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Görüşler (Geri Bildirim) | Görüşler - Cinamonos Fishing Club
			</title>
			<meta name={"description"} content={"Cinamonos Fishing Club'ı gerçek anlamda tanımlayan şey misafirlerimizin deneyimleri ve hikayeleridir. İşte bizimle birlikte olta balıkçılığı yaşam tarzını benimseyen erkeklerden bazı içten referanslar.\n"} />
			<meta property={"og:title"} content={"Görüşler (Geri Bildirim) | Görüşler - Cinamonos Fishing Club"} />
			<meta property={"og:description"} content={"Cinamonos Fishing Club'ı gerçek anlamda tanımlayan şey misafirlerimizin deneyimleri ve hikayeleridir. İşte bizimle birlikte olta balıkçılığı yaşam tarzını benimseyen erkeklerden bazı içten referanslar.\n"} />
			<meta property={"og:image"} content={"https://rescinamonos.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://rescinamonos.com/img/fishing-icons.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<meta name={"msapplication-TileImage"} content={"https://rescinamonos.com/img/fishing-icons.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--вфкл"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Görüşler
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
					Cinamonos Fishing Club'ı gerçek anlamda tanımlayan şey misafirlerimizin deneyimleri ve hikayeleridir. İşte bizimle birlikte olta balıkçılığı yaşam tarzını benimseyen erkeklerden bazı içten referanslar.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text
						margin="0px 0px 100px 0px"
						color="--darkL2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"Cinamonos'deki hafta sonum olağanüstüydü. Rehberler en iyi avlar için tam olarak nereye gidileceğini biliyorlardı ve kiralık ev rahat ve misafirperverdi. Burası her balıkçının hayalindeki yer."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://rescinamonos.com/img/p1.jpg"
							border-radius="50px"
							margin="0px 17px 0px 0px"
						/>
						<Box>
							<Text color="--darkL2" font="--base" margin="0px 0px 2px 0px">
								Ahmet Yıldız
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text
						margin="0px 0px 100px 0px"
						color="--darkL2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"Birinci sınıf ekipmandan rehberlerin uzman tavsiyelerine kadar Cinamonos'deki her şey olağanüstü bir balıkçılık deneyimi için ayarlanmış. Buraya birçok kez geldim ve asla hayal kırıklığına uğratmıyor."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://rescinamonos.com/img/p2.jpg"
							border-radius="50px"
							margin="0px 17px 0px 0px"
						/>
						<Box>
							<Text color="--darkL2" font="--base" margin="0px 0px 2px 0px">
								Murat Çelik
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text
						margin="0px 0px 100px 0px"
						color="--darkL2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"Birinci sınıf ekipmandan rehberlerin uzman tavsiyelerine kadar Cinamonos'deki her şey olağanüstü bir balıkçılık deneyimi için ayarlanmış. Buraya birçok kez geldim ve asla hayal kırıklığına uğratmıyor."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://rescinamonos.com/img/p3.jpg"
							border-radius="50px"
							margin="0px 17px 0px 0px"
						/>
						<Box>
							<Text color="--darkL2" font="--base" margin="0px 0px 2px 0px">
								Emir Karabulut
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text
						margin="0px 0px 100px 0px"
						color="--darkL2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						"Cinamonos sadece balık tutmakla ilgili değil - tüm deneyimle ilgili. Yakala ve pişir servisi günümüzü sonlandırmak için keyifli bir yoldu. Her anından keyif aldım ve geri dönmek için sabırsızlanıyorum."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Image
							width="64px"
							height="64px"
							src="https://rescinamonos.com/img/p4.jpg"
							border-radius="50px"
							margin="0px 17px 0px 0px"
						/>
						<Box>
							<Text color="--darkL2" font="--base" margin="0px 0px 2px 0px">
								Berkcan Demirci
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});